import React from 'react';
import { createTheme } from '@material-ui/core/styles';
import { CheckBoxOutlined } from '@material-ui/icons';
import { viinimaaNavigationBreakpoint } from '../../constants';

import '../../fonts/viniimaa/PlayfairDisplay/PlayfairDisplay.css';

export const colors = {
  black: 'rgb(0, 0, 0)',
  white: 'rgb(255, 255, 255)',
  white50: 'rgba(255, 255, 255, 0.5)',
  whiteTransparent: 'rgba(255, 255, 255, 0.9)',
  gray: 'rgb(204, 204, 204)',
  lighterGray: 'rgb(244, 245, 246)',
  lightGray: 'rgb(230, 230, 230)',
  mediumGray: 'rgb(155, 155, 155)',
  mediumGray2: 'rgb(129, 129, 129)',
  darkGray: 'rgb(51, 51, 51)',
  darkerGray: 'rgb(129, 129, 129)',
  blue: 'rgb(70, 152, 202)',
  lightBlue: 'rgb(164, 200, 225)',
  lightBlueTransparent: 'rgba(164, 200, 225, 0.9)',
  lighterBlue: 'rgb(234, 243, 249)',
  green: 'rgb(139, 181, 80)',
  lightGreen: 'rgb(176, 219, 116)',
  lightGreenTransparent: 'rgba(176, 219, 116, 0.9)',
  lighterGreen: 'rgb(241, 246, 236)',
  yellow: 'rgb(222, 184, 59)',
  lightYellow: 'rgb(233, 225, 134)',
  lighterYellow: 'rgb(255, 246, 233)',
  lightYellowTransparent: 'rgba(233, 225, 134, 0.9)',
  lighterYellow2: '#fff6e9',

  red: 'rgb(161, 21, 60)',
  darkRed: ' rgb(117, 13, 13)',
  lightRed: 'rgb(249, 234, 236)',

  darkOrange: '#ba8142',
  brightBlue: '#43847c',
  brightyellow: '#ffd700',

  brightGreen: '#6c8551',
  brightBrown: '#86482c',

  pink: 'rgb(208, 1, 110)',
  darkBrown: '#6D3332',
  brightOrange: '#FF4F01',
  lightPurple: '#685BC7',
  lightPink: 'rgb(221, 169, 191)',

  primaryBlue: '#1B1D36',
  primaryPurple: '#7b3e92',
};

const theme = createTheme({});
const breakPoints = {
  xs: 0,
  sm: 600,
  md: 840,
  lg: 1280,
  xl: 1920,
};
const primaryTheme = createTheme({
  breakpoints: {
    values: breakPoints,
  },
  mixins: {
    toolbar: {
      minHeight: '70px',
    },
  },
  typography: {
    fontFamily: 'proxima-nova, sans-serif',
    fontWeightLight: '300',
    fontWeightRegular: '400',
    fontWeightMedium: '500',
    fontWeightBold: '700',
    h1: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '1.75rem',
      fontWeight: '700',
      lineHeight: '1.19',
      marginBottom: '1rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '2.625rem',
      },
    },
    h2: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '1.375rem',
      fontWeight: '700',
      lineHeight: 'normal',
      marginBottom: '1rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '1.5rem',
      },
    },
    h3: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '1.125rem',
      fontWeight: '700',
      lineHeight: 'normal',
      marginBottom: '1rem',
    },
    h4: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: 'normal',
      marginBottom: '1rem',
    },
    h5: {
      fontFamily: '"Playfair Display", serif',
      fontSize: '1rem',
      fontWeight: '700',
      lineHeight: 'normal',
      marginBottom: '1rem',
    },
    h6: {
      fontFamily: '"proxima-nova", sans-serif',
      fontSize: '1rem',
      fontWeight: '400',
      lineHeight: 'normal',
      marginBottom: '1rem',
    },
    caption: {
      fontSize: 'inherit',
      fontStyle: 'italic',
      lineHeight: '1.5',
    },
    overline: {
      fontSize: '0.75rem',
      fontWeight: '700',
    },
    body1: {
      fontFamily: 'proxima-nova, sans-serif',
      fontSize: '0.938rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '1rem',
      },
    },
    body2: {
      fontFamily: 'proxima-nova, sans-serif',
      fontSize: '0.75rem',
      [theme.breakpoints.up(breakPoints.md)]: {
        fontSize: '0.875rem',
      },
    },
    navigationLink: {
      fontSize: '1rem',
      fontFamily: 'proxima-nova, sans-serif',
      fontWeight: 'bold',
      color: colors.black,
    },
    descriptionRow: {
      fontSize: 'initial',
    },
  },
  palette: {
    common: {
      black: colors.black,
      white: colors.white,
      gray: colors.gray,
      lightGray: colors.lightGray,
      lighterGray: colors.lighterGray,
      mediumGray: colors.mediumGray,
      darkGray: colors.darkGray,
      blue: colors.blue,
      lightBlue: colors.lightBlue,
      red: colors.red,
      darkRed: colors.darkRed,
      green: colors.green,
      lightGreen: colors.lightGreen,
    },
    primary: {
      main: colors.black,
      contrastText: colors.white50,
    },
    secondary: {
      main: colors.darkGray,
      light: colors.lightYellow,
    },
    text: {
      primary: colors.darkGray,
      secondary: colors.white,
      light: colors.white,
    },
    background: {
      default: colors.white,
      breadcrumbs: colors.lightGray,
      linkBlock: colors.lightGray,
      defaultColumn: 'transparent',
      defaultRow: 'transparent',
    },
    error: {
      main: colors.red,
      light: colors.lightRed,
    },
    success: {
      main: colors.green,
      light: colors.lighterGreen,
    },
    warning: {
      main: colors.brightOrange,
      light: colors.lighterYellow,
    },
    info: {
      main: colors.blue,
      light: colors.lighterBlue,
    },
    headingFontFamily: { fontFamily: '"Playfair Display", serif' },
    divider: colors.lightGray,
    category: {
      outlined: {
        background: colors.lightGray,
        text: colors.primaryPurple,
      },
      text: colors.mediumGray,
      hoverText: colors.primaryPurple,
    },
    footer: {
      border: {
        color: colors.primaryBlue,
        width: '3px',
      },
      divider: colors.green,
      text: colors.black,
      background: colors.white,
      logoBarBackground: colors.lightGray,
      padding: {
        bottom: '50px',
      },
      columns: {
        list: null,
      },
      texts: null,
    },
    header: {
      text: colors.black,
      background: colors.white,
      border: {
        bottomWidth: '3px',
        style: 'solid',
      },
      minHeight: '64px',
      paddingYDesktop: '24px',
      navigation: {
        activeLink: colors.primaryPurple,
        activeLinkBorder: `4px solid ${colors.primaryPurple}`,
        hoverText: colors.primaryPurple,
        subMenuBackground: colors.lighterGray,
        subMenuBulletPoints: colors.primaryPurple,
      },
      icons: {
        color: null,
        backgroundColor: 'transparent',
      },
    },
    search: {
      quickSearch: {
        inputContainer: {
          backgroundColor: colors.primaryBlue,
        },
        hideButton: {
          color: colors.white,
        },
      },
    },
    mobileNavigation: {
      text: colors.black,
      icon: colors.primaryPurple,
      xIconBackground: colors.lighterGray,
      logoBarBottomBorder: colors.primaryBlue,
      navigationElementBorder: colors.lighterGray,
      subNavigationTitleBackground: colors.primaryBlue,
      subNavigationTitleColor: colors.primaryPurple,
    },
    productPage: {
      informationBackground: colors.lighterGray,
      tasteProfiles: {
        orange: colors.darkOrange,
        yellow: colors.brightyellow,
        green: colors.brightGreen,
        brown: colors.brightBrown,
        blue: colors.brightBlue,

        pink: colors.pink,
        darkBrown: colors.darkBrown,
        brightOrange: colors.brightOrange,
        purple: colors.lightPurple,
        lightPink: colors.lightPink,
      },
      mainInfo: {
        accordion: {
          color: colors.primaryPurple,
          information: {
            detailesKey: colors.darkerGray,
          },
        },
        mediaPrint: {
          accordionSummary: {
            display: 'none',
          },
          accordion: {
            backgroundColor: colors.lighterGray,
          },
          accordionDescriptions: {
            maxWidth: '100%',
            paddingRight: '16px',
          },
          accordionAttributes: {
            padding: '0 8px',
            position: 'absolute',
            right: '0',
            top: '380px',
            width: '45%',
            border: 'solid 1px lightgrey',
          },
          informationRow: {
            key: {
              color: colors.black,
              paddingRight: '8px',
              textTransform: 'none',
            },
            value: {},
          },
        },
      },
      productInfo: {
        mediaPrint: {
          price: {
            display: 'none',
          },
          wrapper: {
            display: 'flex',
            flexDirection: 'row-reverse',
          },
          imageSide: {
            justifyContent: 'center',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            flexBasis: '45%',
            maxWidth: '45%',
          },
          informations: {
            position: 'static',
            top: 'auto',
            left: 'auto',
            maxWidth: '55%',
            flexBasis: '55%',
          },
          basicInformation: {
            minHeight: '0px',
            maxWidth: '100%',
          },
        },
      },
      review: {
        articlePadding: theme.spacing(0, 2, 0, 2),
        background: colors.lighterGray,
        divider: colors.darkerGray,
      },
      labelBackground: colors.primaryPurple,
    },
    quote: {
      text: colors.primaryPurple,
      fontSize: '19px',
    },
    button: {
      fontWeight: 'bold',
      textTransform: 'uppercase',
      [theme.breakpoints.up(breakPoints.md)]: {
        padding: '15px 10px 15px 10px',
      },
    },
    memoryListButton: {
      badge: {
        backgroundColor: colors.primaryPurple,
        color: colors.white,
        fontWeight: 'bold',
      },
      actionButton: {
        addToList: {
          border: `solid 1px ${colors.primaryPurple}`,
          color: colors.primaryPurple,
        },
        removefromList: {
          border: `solid 1px ${colors.red}`,
          color: colors.red,
        },
        inList: {
          backgroundColor: colors.primaryPurple,
          color: colors.white,
          '&:hover': {
            color: colors.primaryPurple,
            '@media (hover: none)': {
              backgroundColor: colors.primaryPurple,
              color: colors.white,
            },
          },
        },
      },
    },
    link: {
      link: colors.primaryPurple,
      active: colors.primaryPurple,
      hover: colors.primaryPurple,
    },
    pageTemplates: {
      landingPage: {
        paddingTop: theme.spacing(9),

        [theme.breakpoints.up(350)]: {
          paddingTop: theme.spacing(11),
        },
      },
      otherPageTemplates: {
        paddingTop: theme.spacing(15),
        [theme.breakpoints.up(viinimaaNavigationBreakpoint.Breakpoint)]: {
          paddingTop: theme.spacing(15),
        },
      },
    },
    custobarSubscriptionForm: {
      background: colors.primaryPurple,
      hover: {
        background: colors.lightGreen,
      },
      disabled: {
        background: colors.green,
      },
    },
    siteNotifications: {
      backgroundColor: colors.lighterYellow2,
      color: '#ffa31c',
      padding: theme.spacing(2.5),
      margin: theme.spacing(-3, 0, 4, 0),
      [theme.breakpoints.up(viinimaaNavigationBreakpoint.Breakpoint)]: {
        margin: theme.spacing(-1, 0, 4, 0),
      },
    },
    monopolyPrice: {
      fontFamily: 'proxima-nova, sans-serif',
      reducedPriceColor: colors.red,
    },
  },
  accordion: {
    backgroundColor: colors.white,
    color: colors.primaryPurple,
    border: '',
    expandIcon: {
      hover: {
        backgroundColor: colors.gray,
      },
    },
  },
  languageSelector: {
    border: {
      color: colors.black,
      width: '2px',
    },
    margin: '0',
  },
  linkBlockModule: {
    imageWrapper: {
      maxWidth: '35%',
    },
    textWrapper: {
      maxWidth: '65%',
    },
  },
  table: {
    border: `solid 1px ${colors.black}`,
    borderRadius: 0,
    head: {
      backgroundColor: colors.black,
      color: colors.white,
    },
    row: {
      even: {
        backgroundColor: theme.palette.action.hover,
      },
    },
  },
  listElementCard: {
    content: {},
  },
  slider: {
    arrowBotton: {},
  },
  hr: {},

  imageSpotLink: {
    innerDot: {},
  },
  event: {
    root: {},
    day: {},
    month: {},
    name: {},
    shortDescription: {},
    icon: {},
  },

  overrides: {
    MuiAccordion: {
      root: {
        '&.Mui-expanded': {
          margin: 0,
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        '&.Mui-expanded': {
          backgroundColor: colors.primaryPurple,
          color: colors.white,
          minHeight: 'unset',
        },
      },
      expandIcon: {
        '&.Mui-expanded': {
          color: colors.white,
        },
      },
      content: {
        '&.Mui-expanded': {
          margin: '12px 0',
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        backgroundColor: 'transparent',
      },
      colorPrimary: {
        color: colors.black,
        backgroundColor: colors.white,
      },
    },
    MuiToolbar: {
      regular: {},
      dense: {
        color: colors.white,
      },
    },
    MuiDrawer: {
      root: {
        width: '350px',
        flexShrink: 0,
      },
      paper: {
        width: '350px',
        color: colors.black,
        backgroundColor: colors.white,
      },
    },
    MuiLink: {
      root: {
        color: colors.primaryPurple,
      },
    },
    MuiDialog: {
      paper: {
        margin: '5px',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'inherit',
        borderRadius: '0px',
        padding: '18px 20px',
        fontSize: '1rem',
        minWidth: '0',
      },
      sizeSmall: {
        padding: '9px 18px',
        borderRadius: '0px',
        fontSize: '0.8125rem',
      },
      containedPrimary: {
        boxShadow: 'none',
        border: '2px solid transparent',
        backgroundColor: colors.primaryPurple,
        color: colors.white,
        '&:hover': {
          backgroundColor: colors.white,
          border: `2px solid ${colors.primaryPurple}`,
          boxShadow: 'none',
          color: colors.primaryPurple,
          '@media (hover: none)': {
            backgroundColor: colors.white,
          },
        },
        '&$disabled': {
          color: colors.black,
          opacity: 0.7,
        },
      },
      outlinedPrimary: {
        border: `2px solid ${colors.primaryPurple}`,
        color: colors.primaryPurple,
        '&:hover': {
          backgroundColor: colors.primaryPurple,
          border: `2px solid transparent`,
          color: colors.white,
          '@media (hover: none)': {
            backgroundColor: colors.primaryPurple,
          },
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        color: colors.darkGray,
      },
      separator: {
        fontSize: '1.5rem',
        marginLeft: '16px',
        marginRight: '16px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 8px',
        [theme.breakpoints.up('sm')]: {
          padding: '8px 24px',
        },
      },
    },
    MuiInputBase: {
      root: {
        border: '1px solid',
        borderColor: colors.gray,
        overflow: 'hidden',
        backgroundColor: colors.lightGray,
        '&:hover:not($disabled)': {
          borderColor: colors.gray,
        },
        '& > svg': {
          color: colors.gray,
        },
        '&$focused': {
          boxShadow: '0 2px 6px 0 rgba(0, 0, 0, 0.24)',
          borderColor: colors.primaryPurple,
          '&:hover:not($disabled)': {
            borderColor: colors.primaryPurple,
          },
        },
        '&$error:not($focused)': {
          borderColor: theme.palette.error.main,
          '&:hover:not($disabled)': {
            borderColor: theme.palette.error.main,
          },
        },
        '&$disabled': {
          backgroundColor: colors.lightGray,
        },
      },
      adornedStart: {
        paddingLeft: '0.5rem',
      },
      adornedEnd: {
        paddingRight: '0.5rem',
      },
      input: {
        fontSize: '1rem',
        padding: '0.625rem 1rem',
        '&:not(:first-child)': {
          paddingLeft: '0.5rem',
        },
        '&:not(:last-child)': {
          paddingRight: '0.5rem',
        },
        '&::placeholder': {
          fontStyle: 'italic',
        },
      },
    },
    MuiInputLabel: {
      root: {
        textTransform: 'uppercase',
        marginLeft: '0.75rem',
      },
      shrink: {
        marginTop: '-8px',
        transform: 'translate(0, 1.5px) scale(1)',
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.darkGray,
        '&$error': {
          color: colors.darkRed,
        },
      },
      asterisk: {
        '&$error': {
          color: colors.darkRed,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: colors.darkGray,
        marginLeft: '0.75rem',
        '&$error': {
          color: colors.darkRed,
        },
      },
    },

    MuiCheckbox: {
      root: {
        color: colors.primaryPurple,
        '&$checked': {
          color: colors.primaryPurple,
        },
      },
    },
    MuiRadio: {
      root: {
        color: colors.primaryPurple,
        '&$checked': {
          color: colors.primaryPurple,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: theme.spacing(2),
      },
    },
    MuiTypography: {
      root: {
        '& > li::Marker': {
          color: colors.primaryPurple,
        },
      },
    },
    MuiList: {
      root: {
        listStyle: 'square',
        '& > li::Marker': {
          color: colors.primaryPurple,
        },
      },
    },
    MuiCollapse: {
      hidden: {
        '@media print': {
          visibility: 'visible',
          height: 'auto',
        },
      },
    },
    MuiPaginationItem: {
      textPrimary: {
        '&.Mui-selected': {
          backgroundColor: colors.primaryPurple,
          color: colors.white,
          fontWeight: 'bold',
        },
      },
    },

    MuiSlider: {
      thumb: {
        color: colors.primaryPurple,
        '&:after': {
          display: 'none',
        },
      },
      track: {
        color: colors.primaryPurple,
      },
    },
    PrivateValueLabel: {
      circle: {
        borderRadius: '50%',
      },
      label: {
        color: colors.white,
        fontWeight: 'bold',
      },
      offset: {
        top: 0,
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'primary',
    },
    MuiIconButton: {
      color: 'primary',
    },
    MuiButton: {
      color: 'primary',
      variant: 'contained',
    },
    MuiLink: {
      variant: 'body1',
    },
    MuiContainer: {
      disableGutters: false,
    },
    MuiBreadcrumbs: {
      separator: '›',
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      shrink: true,
    },
    MuiCheckbox: {
      checkedIcon: <CheckBoxOutlined />,
    },
  },
});

export default primaryTheme;
